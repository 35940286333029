<template>
  <!-- Row -->
  <b-row>
    <b-col
      cols="4"
    >
      <b-form-group
        label="Field Key"
      >
        <validation-provider
          #default="{ errors }"
          name="Field Key"
          rules="required"
          :vid="`Field Key${conditionIndex}${conditionBlockIndex}`"
        >
          <v-select
            v-model="condition.field_id"
            :options="fieldsData"
            label="text"
            :clearable="false"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :reduce="option => option.value"
            placeholder="Select a Field"
            @input="setOptions"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

    </b-col>
    <b-col
      cols="3"
    >
      <b-form-group
        label="Operator"
      >
        <validation-provider
          #default="{ errors }"
          name="Operator"
          rules="required"
          :vid="`Operator${conditionIndex}${conditionBlockIndex}`"
        >
          <v-select
            v-model="condition.operator"
            :options="operatorsOptions"
            :disabled="!condition.field_id"
            label="text"
            :clearable="false"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :reduce="option => option.value"
            placeholder="Select an Operator"
            @input="setOperator"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col
      cols="4"
    >
      <b-form-group
        label="Value"
      >
        <template>
          <validation-provider
            #default="{ errors }"
            name="Value"
            rules="required"
            :vid="`Value${conditionIndex}${conditionBlockIndex}`"
          >
            <v-select
              v-if="valueOptions.length >= 1 && condition.operator !== '==contains'"
              v-model="condition.value"
              placeholder="Select Value"
              :clearable="false"
              :options="valueOptions"
            />
            <v-select
              v-else-if="condition.operator == '==contains'"
              :ref="conditionIndex"
              v-model="condition.value"
              placeholder="Select Value"
              :clearable="false"
              :taggable="valueOptions.length == 0"
              :multiple="condition.operator === '==contains'"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="valueOptions"
              :class="{'is-invalid': errors.length > 0 }"
            >
              <template
                v-if="valueOptions.length == 0"
                #no-options
              >
                Type and press Enter to add value.
              </template>
            </v-select>
            <b-form-input
              v-else
              v-model="condition.value"
              :disabled="!condition.field_id"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </template>
      </b-form-group>
    </b-col>
    <b-col
      cols="1"
      align-self="center"
    >
      <feather-icon
        v-if="!hideDeleteRowIcon"
        icon="Trash2Icon"
        size="21"
        class="text-primary cursor-pointer"
        @click="removeConditionRow"
      />
    </b-col>
  </b-row>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import {
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'ConditionRow',
  components: {
    BFormGroup,
    BFormInput,
    BCol,
    BRow,
    ValidationProvider,
    vSelect,
  },
  props: {
    fieldsData: {
      type: Array,
      default: () => {},
    },
    condition: {
      type: Object,
      default: () => {},
    },
    conditionIndex: {
      type: Number,
      required: true,
    },
    conditionBlockIndex: {
      type: Number,
      required: true,
    },
    conditionBlockLength: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      valueOptions: [],
      operators: [
        { value: '==', text: 'equals', appropriate: ['short_text', 'long_text', 'email', 'number', 'phone', 'date', 'dropdown', 'multi_select', 'checkbox', 'radio_button', 'group_selection'] },
        { value: '!=', text: 'not equals', appropriate: ['short_text', 'long_text', 'email', 'number', 'phone', 'date', 'dropdown', 'multi_select', 'checkbox', 'radio_button', 'group_selection'] },
        { value: '==contains', text: 'contains', appropriate: ['short_text', 'long_text', 'email', 'number', 'phone', 'date', 'dropdown', 'multi_select', 'checkbox', 'radio_button', 'group_selection'] },
        { value: '!=contains', text: 'does not contain', appropriate: ['short_text', 'long_text', 'email', 'number', 'phone', 'date', 'dropdown', 'multi_select', 'checkbox', 'radio_button', 'group_selection'] },
        { value: '==empty', text: 'is empty', appropriate: ['short_text', 'long_text', 'email', 'number', 'phone', 'date', 'dropdown', 'multi_select', 'checkbox', 'radio_button'] },
        { value: '!=empty', text: 'is filled', appropriate: ['short_text', 'long_text', 'email', 'number', 'phone', 'date', 'dropdown', 'multi_select', 'checkbox', 'radio_button'] },
        { value: '<', text: 'is less than', appropriate: ['number'] },
        { value: '>', text: 'is greater than', appropriate: ['number'] },
      ],
      operatorsOptions: [],
      required,
    }
  },
  computed: {
    hideDeleteRowIcon() {
      return this.conditionIndex === 0 && this.conditionBlockIndex === 0 && this.conditionBlockLength === 1
    },
  },
  created() {
    this.setOptions(this.condition.field_id)
  },
  methods: {
    setOptions(fieldId) {
      const field = this.fieldsData.find(item => item.value === fieldId)
      const intermediateResult = field?.meta

      if (intermediateResult && intermediateResult.length > 0) {
        this.valueOptions = JSON.parse(JSON.stringify(intermediateResult))
      } else {
        this.valueOptions = []
      }

      this.operatorsOptions = this.operators
        .filter(item => item.appropriate.includes(field?.type))
        .reduce((arr, item) => {
          arr.push({
            value: item.value,
            text: item.text,
          })
          return arr
        }, [])
    },
    removeConditionRow() {
      this.$emit('removeConditionRow')
    },
    setOperator() {
      if (this.condition.operator === '==contains' && this.condition.value === '' && this.valueOptions.length > 1) {
        this.condition.value = []
      }
    },
    handleInputFocus(refId) {
      // Focus footerInput manually
      this.$refs.footerInput.focus()
      // Keep the dropdown open
      this.$refs[refId].open = true
    },
  },
}
</script>

<style>

</style>
